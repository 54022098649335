<template>
  <div class="d-flex flex-column">
    <v-sheet height="fit-content" class="flex-grow-1 flex-shrink-0">
      <v-toolbar 
        flat
        height="fit-content"
      >
        <slot
          class="d-flex"
          name="toolbar"
          v-bind="{
            calendar: $refs.calendar,
            prev: prev,
            next: next,
          }"
        >
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
            >Oggi</v-btn
          >
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">{{
            $refs.calendar.title
          }}</v-toolbar-title>
          <v-menu offset-y v-if="showGoToDate" :close-on-content-click="false">
            <template v-slot:activator="{ attrs: attrsMenu, on: onMenu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs: attrsTooltip, on: onTooltip }">
                  <v-btn icon v-on="{ ...onMenu, ...onTooltip}" v-bind="{ ...attrsMenu, ...attrsTooltip}" class="ml-2">
                    <v-icon>mdi-calendar-cursor</v-icon>
                  </v-btn>
                </template>
                <span> Seleziona Data </span>
              </v-tooltip>
            </template>
            <v-date-picker locale="it" v-model="focus" @input="$emit('input', new Date($event))" first-day-of-week="1" color="back-color-gradient"></v-date-picker>
          </v-menu>
          <template v-if="showZoom">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs: attrsTooltip, on: onTooltip }">
                <v-btn
                  icon
                  v-on="onTooltip"
                  v-bind="attrsTooltip"
                  @click="zoomOut"
                >
                  <v-icon>mdi-magnify-minus-outline</v-icon>
                </v-btn>
              </template>
              <span> Zoom Out</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs: attrsTooltip, on: onTooltip }">
                <v-btn
                  icon
                  v-on="onTooltip"
                  v-bind="attrsTooltip"
                  @click="zoomIn"
                >
                  <v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
              </template>
              <span> Zoom In</span>
            </v-tooltip>
          </template>
          <slot
            name="toolbar-append"
            v-bind="{
              calendar: $refs.calendar,
              prev: prev,
              next: next,
            }"
          ></slot>
          <v-spacer></v-spacer>
          <v-scroll-y-transition>
            <v-progress-circular
              indeterminate
              color="grey"
              class="mr-4"
              width="2"
              v-if="localLoading"
            ></v-progress-circular>
          </v-scroll-y-transition>
          <v-autocomplete
            v-model="selectedCategories"
            @input="handleSelectedCategoriesChange"
            :items="categories"
            :item-text="categoryTextKey"
            :item-value="categoryValueKey"
            filled
            :label="categoryLabel"
            :placeholder="categoryPlaceholder"
            class="mt-2"
            dense
            multiple
            clearable
            rounded
            hide-details="auto"
            :style="{ 'max-width': selectMaxWidth }"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">
                {{ item[categoryTextKey] }}
              </span>
              <span
                v-else-if="index < 1"
              >
                , {{ item[categoryTextKey] }}
              </span>
              <span
                v-else-if="index === 1"
                class="grey--text text-caption"
              >
                &nbsp; (e altri {{ selectedCategories.length - 3 }})
              </span>
            </template>
          </v-autocomplete>
        </slot>
      </v-toolbar>
    </v-sheet>
    <div class="flex-shrink-1 calendar-container">
      <v-calendar
        :locale="locale"
        ref="calendar"
        v-model="focus"
        :color="color"
        type="category"
        category-show-all
        :interval-minutes="15"
        :interval-height="intervalHeight"
        :interval-count="96"
        :interval-format="intervalFormat"
        :interval-style="intervalStyle"
        :event-category="eventCategory"
        :categories="filteredCategories"
        :events="events"
        :event-end="eventEnd"
        :event-start="eventStart"
        :event-name="eventName"
        :event-timed="eventTimed"
        :event-color="getEventColor"
        :event-text-color="getEventTextColor"
        :event-ripple="false"
        @change="handleCalendarChange"
        @mousedown:event="startDrag"
        @touchstart:event="startDrag"
        @mousedown:time-category="startTime($event)"
        @touchstart:time-category="touchStartTime($event)"
        @mousemove:time-category="mouseMove"
        @touchmove:time-category="touchMove"
        @touchmove:event="touchMoveEventHandler"
        @contextmenu:event="openMenuDialog"
        @touchend:event="endTouch"
        @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag"
        :style="{
          '--category-width': formattedCategoryWidth
        }"
        @input="emit('input', $event)"
        class="calendar"
      >
        <template  v-slot:day-body="{ date, week, category }" v-if="showTimeline">
          <div
            v-if="!!dragEvent"
            class="v-event-time "
            :class="{ first: date === week[0].date }"
            :style="{
              top: eventY,
              '--background-color': getEventColor(dragEvent),
            }"
          >
            {{ eventTime }}
          </div>
          <div
            class="v-current-time "
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>

          <template
            v-for="(unavailability, index) in unavailabilities" 
          >
            <div 
              v-if="!unavailability.category || unavailability.category == category"
              :key="index"
              :style="{
                'position': 'absolute',
                top: timesToPixelMapping[unavailability.from] + 'px',
                height: timesToPixelMapping[unavailability.to] - timesToPixelMapping[unavailability.from] + 'px',
                width: '100%',
                'background-color': unavailability.color
              }" 
              class="stripe-unavailabilities"
            ></div>
          </template>
        </template>
        <template v-slot:event="{ event, timed, eventSummary, eventParsed }">
          <v-touch @doubletap="openMenuDialog({event})" :class="[ event.locked ? 'stripe': '', 'v-event-draggable']" style="height: 100%; width: 100%; overflow: hidden">
            <slot name="event" v-bind="{ event, timed, eventSummary, eventParsed }">
              <v-tooltip bottom open-delay="2000">
                <template v-slot:activator="{ attrs: attrsTooltip, on: onTooltip }">
                  <div 
                    icon
                    v-on="onTooltip"
                    v-bind="attrsTooltip"  
                    style="width: 100%;">
                        <div class="d-flex flex-row">
                          <div style="margin-left: -3px; margin-top: -2px">
                            <slot
                              name="event-actions"
                              v-bind="{
                                textColor: getEventTextColor(event),
                                eventSummary,
                                timed,
                                event,
                              }"
                            >
                            </slot>
                          </div>
                          <div class="d-flex flex-column" style="font-size: 9.2px; margin-right: 2px; font-weight: bold; margin-top: 3px"  v-if="(calcEventMinutes(eventParsed) >= 30)">
                            {{eventParsed.start.time}}
                          </div>
                          <div class="d-flex flex-column" style="font-size: 9.2px; font-weight: bold; margin-top: 3px">
                            {{eventName(event)}}
                          </div>
                        </div>
                        <div class="d-flex flex-row">
                          <div class="d-flex flex-column" style="white-space: pre-line; font-size: 9px; margin-top: -2px">
                            {{!!eventDetail ? eventDetail(eventParsed) : ""}}            
                          </div>
                        </div>
                    </div>
                </template>
                <span> {{eventName(event)}} </span>
              </v-tooltip>
            </slot>
          </v-touch>
          <div
            v-if="timed"
            class="v-event-drag-bottom "
            @mousedown.stop="extendBottom(event)"
            @touchstart.stop="extendBottom(event)"
          ></div>
        </template>
        <template v-slot:category="{ category }">
          <div class="text-center font-weight-regular px-2">
            {{ categoryName(category) }}
          </div>
          <div
            v-if="categorySubTextKey"
            class="text-center text-caption font-weight-regular px-2"
          >
            {{ categorySubName(category) }} {{ categorySubText }}
          </div>
        </template>
      </v-calendar>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import colorConstrast from "@/mixins/common/colorContrast";
import eventGroupManagment from "@/services/calendar/eventGroup.service.js";
import { groupBy, cloneDeep } from "lodash";

export default {
  name: "CategoriesCalendar",
  mixins: [colorConstrast],
  data: function () {
    let focus = new Date().toISOString().substring(0, 10);
    if (!!this.value)
      focus = new Date(this.value).toISOString().substring(0, 10);

    return {
      localLoading: false,
      focus: focus,
      events: [],
      categories: [],
      selectedCategories: [],
      unavailabilities: [],

      oldDragEvent: null,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      intervalHeight: this.intervalHeightDefault,
      categoryStartingDrag: null,

      // new event prefilled
      prefilledCreateStart: undefined,
      prefilledCreateEnd: undefined,
      prefilledCategory: undefined,

      newTouchCategory: undefined,
      ready: false,
      printLoading: false,
      rightNow: new Date().getHours(),

      timesToPixelMapping: {
        // time: pixels
      },
    };
  },
  props: {
    selectMaxWidth: {
      type: String,
      default: "500px",
    },
    intervalHeightDefault: {
      type: Number,
      default: 12,
    },
    categoryWidth: {
      type: Number,
      default: 200,
    },
    value: {},
    color: {
      type: String,
      default: "default",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    categoryLabel: {
      type: String,
      default: "Categories",
    },
    categoryPlaceholder: {
      type: String,
      default: "All categories",
    },
    categoryTextKey: {
      type: String,
      default: "text",
    },
    categorySubTextKey: {
      type: String,
      default: "text",
    },
    categorySubText: {
      type: String,
      default: null,
    },
    categoryValueKey: {
      type: String,
      default: "value",
    },
    categoriesFetcher: {
      default: function () {
        return () => {
          return Promise.resolve([]);
        };
      },
    },
    canManage: {
      type: Boolean,
      default: false
    },
    eventCategorySetter: {
      default: function () {
        return () => {};
      },
    },
    eventsFetcher: {
      default: function () {
        return (start, end) => {
          return Promise.resolve([]);
        };
      },
    },
    /* 
      ({
        from: Date,
        to: Date
      }) => [
        ...,
        {
          category: Key of the category,
          from: time string (hh:mm),
          to: time string (hh:mm),
          color: css background color property
        }
      ]
    */
    unavailabilitiesFetcher: {
      default: function () {
        return () => {
          return Promise.resolve([]);
        };
      },
    },
    scrollToTime: {
      type: String,
    },
    eventCategory: {
      default: undefined,
    },
    eventEnd: {
      type: String,
      default: "end",
    },
    eventStart: {
      type: String,
      default: "start",
    },
    moveBetweenCategory: {
      default: true,
    },
    moveInTime: {
      default: true,
    },
    locale: {
      type: String,
      default: "it",
    },
    eventColor: {},
    eventTextColor: {},
    eventName: {
      default: undefined,
    },
    eventDetail: {
      default: undefined
    },
    eventTimed: {
      default: undefined,
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    showTimeline: {
      type: Boolean,
      default: true,
    },
    showGoToDate: {
      type: Boolean,
      default: true,
    },
    showZoom: {
      type: Boolean,
      default: true,
    },
    roundTo: {
      type: Number,
      default: 15,
    },
    externalFocus: {
      type: String,
      default: undefined
    }
  },
  mounted() {
    this.intervalHeight = Number(localStorage.getItem('calendarIntervalHeight')) || this.intervalHeightDefault

    if (!!this.externalFocus) {
      this.focus = this.externalFocus
    }

    let hasSomeParentTheClass = (element, classname) => {
      if (!!element.className && element.className.split(" ").indexOf(classname) >= 0)
        return true;
      else return (
        element.parentNode &&
        hasSomeParentTheClass(element.parentNode, classname)
      );
    };

    document
      .getElementsByClassName("v-calendar-daily__scroll-area")[0]
      .addEventListener("touchmove", function (e) {
        if (hasSomeParentTheClass(e.target, "v-event-draggable") || e.target.classList.contains("v-event-drag-bottom")) {
          e.preventDefault();
        }
      });

    this.loadData().then(()=>{
      this.selectedCategories = JSON.parse(localStorage.getItem('calendarSelectedCategories')) || this.categories.map(c => c[this.categoryValueKey])
    });

    this.bus.$on("reload-events", () => {
      this.fetchEvents();
    });

    this.bus.$on("reload-unavailabilities", () => {
      this.fetchUnavailabilities();
    });

    this.bus.$on("reload-categories", this.loadData)

    this.ready = true;
    this.updateTime();
    this.emit("input", this.focus);

    this.fetchUnavailabilities()

    if(!!this.scrollToTime) {
      const calendarScrollContainer = this.$refs.calendar.$el
      if(!!calendarScrollContainer) {
        calendarScrollContainer.scrollTop = this.timeToY(this.scrollToTime)
      }
    }
  },
  methods: {
    getEventName(eventParsed) {
      return this.eventName(eventParsed)
    },
    fetchUnavailabilities() {
      let beginningOfDay = new Date(this.focus);
      beginningOfDay.setHours(0);
      beginningOfDay.setMinutes(0);

      let endOfDay = new Date(this.focus);
      endOfDay.setHours(23);
      endOfDay.setMinutes(59);

      this.unavailabilitiesFetcher({
        from: beginningOfDay,
        to: endOfDay
      }).then((unavailabilities) => {
        this.unavailabilities = unavailabilities
        this.timesToPixelMapping = {}
        this.recalculateTimeToPixel()
      })
    },
    recalculateTimeToPixel() {
      for(let i = 0; i < this.unavailabilities.length; i ++) {
        this.timesToPixelMapping[this.unavailabilities[i].from] = this.timeToY(this.unavailabilities[i].from)
        this.timesToPixelMapping[this.unavailabilities[i].to] = this.timeToY(this.unavailabilities[i].to)
      }
    },
    emit(event, ...params) {
      this.$emit(event, ...params);
      if (this.bus) {
        this.bus.$emit(event, ...params);
      }
    },
    async loadData() {
      this.localLoading = true;
      let result = await this.categoriesFetcher()
      this.categories = result;
      if(!!this.$refs.calendar)
        this.$refs.calendar.checkChange();
      this.localLoading = false;
    },
    handleCalendarChange() {
      this.fetchEvents()
      this.fetchUnavailabilities()
    },
    fetchEvents() {
      let beginningOfDay = new Date(this.focus);
      beginningOfDay.setHours(0);
      beginningOfDay.setMinutes(0);

      let endOfDay = new Date(this.focus);
      endOfDay.setHours(23);
      endOfDay.setMinutes(59);

      this.localLoading = true;
      this.eventsFetcher(beginningOfDay, endOfDay).then((events) => {
        this.events = events;
        this.localLoading = false;
      }).catch(err =>
        console.error(err)
      );
    },
    getEventColor(event) {
      if (!!this.eventColor && typeof this.eventColor == "string") {
        return this.eventColor;
      } else if (!!this.eventColor && typeof this.eventColor == "function") {
        let color = this.eventColor(event);
        return color || "#050505";
      } else {
        return "#050505";
      }
    },
    getEventTextColor(event) {
      if (!this.eventTextColor) {
        let backgroundColor = this.getEventColor(event);
        return this.blackOrWhiteContrast(backgroundColor);
      } else {
        if (typeof this.eventTextColor == "string") {
          return event[this.eventTextColor];
        } else if (this.eventTextColor == "function") {
          return this.eventTextColor(event);
        } else {
          return "#050505";
        }
      }
    },
    setToday() {
      this.focus = new Date().toISOString().substring(0, 10);
      this.$emit('input', new Date(this.focus))
    },
    prev() {
      this.$refs.calendar.prev();
      this.$nextTick(() => {
        this.$emit('input', new Date(this.focus))
      })
    },
    next() {
      this.$refs.calendar.next();
      this.$nextTick(() => {
        this.$emit('input', new Date(this.focus))
      })
    },
    zoomOut() {
      let difference = this.intervalHeight - 10;
      difference <= 13.5 ? (this.intervalHeight = 13.5) : (this.intervalHeight -= 10)
      localStorage.setItem('calendarIntervalHeight', this.intervalHeight)

      this.$nextTick(() => {
        this.recalculateTimeToPixel()
        this.$forceUpdate()
        const calendarScrollContainer = this.$refs.calendar.$el
        if(!!calendarScrollContainer) {
          let hour = this.rightNow + ':00'
          calendarScrollContainer.scrollTop = this.timeToY(hour)
        }
      })
    },
    zoomIn() {
      this.intervalHeight >= 200 ? (this.intervalHeight = 200) : (this.intervalHeight += 10)
      localStorage.setItem('calendarIntervalHeight', this.intervalHeight)

      this.$nextTick(() => {
        this.recalculateTimeToPixel()
        this.$forceUpdate()
        const calendarScrollContainer = this.$refs.calendar.$el
        if(!!calendarScrollContainer) {
          let hour = this.rightNow + ':00'
          calendarScrollContainer.scrollTop = this.timeToY(hour)
        }
      })
    },
    findCategoryByKey(key) {
      for (let i = 0; i < this.categories.length; i++) {
        if (
          this.categories[i][this.categoryValueKey].toString() == key.toString()
        ) {
          return this.categories[i];
        }
      }
    },
    categoryName(categoryId) {
      if (typeof categoryId === "object") {
        categoryId = categoryId.categoryName;
      }
      return this.groupedCategories[categoryId][0][this.categoryTextKey];
    },
    categorySubName(categoryId) {
      if (typeof categoryId === "object") {
        categoryId = categoryId.categoryName;
      }
      return this.groupedCategories[categoryId][0][this.categorySubTextKey];
    },
    openNewEventForm() {
      let prefilledEvent = {};
      prefilledEvent[this.eventStart] = this.prefilledCreateStart;
      prefilledEvent[this.eventEnd] = this.prefilledCreateEnd;
      prefilledEvent["category"] = this.prefilledCategory;

      this.emit("new-event", prefilledEvent);
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundDownTime = this.roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    startDrag({ event, timed }) {
      if (!!this.canManage && !event.locked && event && timed ) {
        this.oldDragEvent = cloneDeep(event);
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
        this.newTouchCategory = this.getEventCategory(event)
        this.categoryStartingDrag = this.getEventCategory(event)
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent[this.eventStart];

        this.dragTime = mouse - start;
      } else {
        this.prefilledCreateStart = new Date(this.roundTime(mouse));
        this.prefilledCreateEnd = this.prefilledCreateStart;
        this.prefilledCreateEnd = new Date(this.roundTime(mouse) + 3600000);
        let categoryKey = tms.category;
        if (typeof categoryKey === "object") {
          categoryKey = categoryKey.categoryName;
        }

        this.prefilledCategory = this.findCategoryByKey(categoryKey);
        this.openNewEventForm();
      }
    },
    touchStartTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null ) {
        const start = this.dragEvent[this.eventStart];

        this.dragTime = mouse - start;
      }
    },
    extendBottom(event) {
      if(!event.locked && this.canManage) {
        this.createEvent = event;
        this.oldDragEvent = cloneDeep(event);
        this.createStart = event[this.eventStart];
        this.extendOriginal = event[this.eventEnd];
      }
    },
    getCategoryByTouchPosition(event) {
      const elements = document.getElementsByClassName('v-calendar-daily__day v-present')
      const touchX = event.targetTouches[0].pageX, touchY = event.targetTouches[0].pageY

      for(let i = 0; i < elements.length; i += 1) {
        var rect = elements[i].getBoundingClientRect()
        if(touchX > rect.x && touchX < (rect.x + rect.width)) {
          return this.categories[i]
        }
      }
    },
    touchMove(tms, event) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent[this.eventStart];
        const end = this.dragEvent[this.eventEnd];
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        if(this.canMoveInTime(this.dragEvent) || !!this.dragEvent.custom){
          this.dragEvent[this.eventStart] = newStart;
          this.dragEvent[this.eventEnd] = newEnd;
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        let min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        if(!!this.createEvent[this.eventStart])
          min = Math.max(min, this.createEvent[this.eventStart]);


        this.createEvent[this.eventStart] = min;
        this.createEvent[this.eventEnd] = max;
      }
    },
    touchMoveEventHandler(params) {
      if (!!this.dragEvent && this.canMoveBetweenCategory(this.dragEvent)) {
        this.newTouchCategory = this.getCategoryByTouchPosition(params.nativeEvent)
      }
    },
    endTouch() {
      if (this.dragEvent && this.canMoveBetweenCategory(this.dragEvent) && !!this.newTouchCategory) {
        if (typeof this.eventCategory === "function") {
          if (!this.eventCategorySetter || typeof this.eventCategory !== "function") {
            throw new Error("event category setter is not defined while moving between category and event category is a function");
          } else {
            let results = this.eventCategorySetter(this.dragEvent, this.newTouchCategory);
            if (!!results) this.dragEvent = results;
          }
        } else {
          this.dragEvent[this.eventCategory] = this.newTouchCategory;
        }
      }
      this.endDrag();
    },
    calcEventMinutes(event){
      if(!event.start || !event.end || !event.start.time || !event.end.time)
       return 0
      const timeStart = new Date("01/01/2007 " + event.start.time)
      const timeEnd = new Date("01/01/2007 " + event.end.time )
      const diff = Math.abs(timeEnd - timeStart);
      return  Math.floor((diff/1000)/60);
    },
    getEventCategory(event) {
      if (typeof this.eventCategory === "function") {
        return this.eventCategory(event);
      } else {
        return event[this.eventCategory];
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent[this.eventStart];
        const end = this.dragEvent[this.eventEnd];
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        if(this.canMoveInTime(this.dragEvent) || !!this.dragEvent.custom) {
          this.dragEvent[this.eventStart] = newStart;
          this.dragEvent[this.eventEnd] = newEnd; 
        }

        if (this.canMoveBetweenCategory(this.dragEvent) ) {
          const newCategory = this.categories.filter(
            (el) =>
              el[this.categoryValueKey].toString() == tms.category.toString()
          )[0];
          if (typeof this.eventCategory === "function") {
            if (
              !this.eventCategorySetter ||
              typeof this.eventCategory !== "function"
            ) {
              throw new Error(
                "event category setter is not defined while moving between category and event category is a function"
              );
            } else {
              let results = this.eventCategorySetter(
                this.dragEvent,
                newCategory,
                this.categoryStartingDrag
              );
              if (!!results) this.dragEvent = results;
            }
          } else {
            this.dragEvent[this.eventCategory] = newCategory;
          }
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        let min = Math.min(mouseRounded, this.createStart);
        if(!!this.createEvent[this.eventStart])
          min = Math.max(min, this.createEvent[this.eventStart]);

        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent[this.eventStart] = min;
        this.createEvent[this.eventEnd] = max;
      }
    },
    canMoveBetweenCategory(event) {
      if (typeof this.moveBetweenCategory == "function") {
        return this.moveBetweenCategory(event);
      } else {
        return this.moveBetweenCategory;
      }
    },
    canMoveInTime(event) {
      if (typeof this.moveInTime == "function") {
        return this.moveInTime(event);
      } else {
        return this.moveInTime;
      }
    },
    endDrag() {
      if (this.dragEvent ) {
        this.emit("end-drag", this.dragEvent, this.oldDragEvent);
      } else {
        this.emit("end-drag", this.createEvent, this.oldDragEvent);
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent[this.eventEnd] = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    openMenuDialog( {event} ) {
      this.emit("show-event", event);
    },
    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? "" : "0") + n;
    },
    getCurrentTime() {
      return this.calendar
        ? this.calendar.times.now.hour * 60 + this.calendar.times.now.minute
        : 0;
    },
    updateTime() {
      setInterval(() => this.calendar.updateTimes(), 60 * 1000);
    },
    printAppointments() {
      this.printLoading = true

      let beginningOfDay = new Date(this.focus);
      beginningOfDay.setHours(0);
      beginningOfDay.setMinutes(0);

      let endOfDay = new Date(this.focus);
      endOfDay.setHours(23);
      endOfDay.setMinutes(59);

      eventGroupManagment.print(beginningOfDay, endOfDay).then(() => {
        this.printLoading = false
      })
    },
    timeToY(time) {
      if (!this.calendar && !this.isMounted) {
        return -1;
      }

      // let minutes = this.getZeroPad(
      //   new Date(this.dragEvent[this.eventStart]).getMinutes()
      // );
      // let hours = this.getZeroPad(
      //   new Date(this.dragEvent[this.eventStart]).getHours()
      // );
      return this.calendar.timeToY(time);
    },
    intervalStyle(interval){
      return{
        borderTop: (!interval.minute ? '1px solid grey' : '1px dashed #e0e0e0')
      }
    },
    intervalFormat(interval){
      return !interval.minute ? interval.time : ''
    },
    handleSelectedCategoriesChange() {
      localStorage.setItem('calendarSelectedCategories', JSON.stringify(this.selectedCategories))
    },
  },
  watch: {
    value(newValue) {
      this.focus = new Date(newValue).toISOString().substring(0, 10);
    },
    categoriesFetcher(newValue) {
      if(!!newValue) this.loadData()
    }
  },
  computed: {
    filteredCategories() {
      let cats = [];
      for (let i = 0; i < this.categories.length; i++) {
        if (
          !this.categoriesSelected ||
          (this.categoriesSelected &&
            this.selectedCategories.includes(
              this.categories[i][this.categoryValueKey]
            ))
        ) {
          cats.push(this.categories[i][this.categoryValueKey].toString());
        }
      }
      return cats;
    },
    categoriesSelected() {
      return this.selectedCategories && this.selectedCategories.length > 0;
    },
    groupedCategories() {
      return groupBy(this.categories, (cat) => {
        return cat[this.categoryValueKey];
      });
    },
    loadingRunning() {
      return this.loading || this.localLoading;
    },
    calendar() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.calendar
        ? this.calendar.timeToY(this.calendar.times.now) + "px"
        : "-10px";
    },
    eventY() {
      if (!this.calendar && !this.isMounted) {
        return -1;
      }
      if (!this.dragEvent || this.dragEvent === null) {
        return -1;
      }

      let minutes = this.getZeroPad(
        new Date(this.dragEvent[this.eventStart]).getMinutes()
      );
      let hours = this.getZeroPad(
        new Date(this.dragEvent[this.eventStart]).getHours()
      );
      return this.calendar.timeToY(hours + ":" + minutes) + "px";
    },
    eventTime() {
      const cal = this.$refs.calendar;
      if (!cal && !this.isMounted) {
        return -1;
      }
      if (!this.dragEvent || this.dragEvent === null) {
        return -1;
      }

      let minutes = this.getZeroPad(
        new Date(this.dragEvent[this.eventStart]).getMinutes()
      );
      let hours = this.getZeroPad(
        new Date(this.dragEvent[this.eventStart]).getHours()
      );
      return hours + ":" + minutes;
    },
    formattedCategoryWidth() {
      return this.categoryWidth + 'px'
    }
  },
};
</script>

<style scoped lang="scss">

.calendar-container {
  overflow: auto;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;

}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #8a77e2;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.v-event-time {
  height: 2px;
  background-color: var(--background-color);
  position: absolute;
  left: -1px;
  right: 0;
}

::v-deep .v-toolbar__content {
  flex-wrap: wrap;
}

.stripe {
  pointer-events: none;
  color: black;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 1px,
    #ccc 10px,
    #ccc 2px
  ),
  linear-gradient(
    to bottom,
    #eee,
    #999
  )
}

.stripe-unavailabilities {
  opacity: 0.5;
  pointer-events: none;
  color: black;
  background-color: rgb(138,119,226);
}

.calendar::v-deep .v-calendar-daily__day {
  min-width: var(--category-width) !important;
}

.calendar::v-deep .v-calendar-category__column-header {
  min-width: var(--category-width) !important;
}
</style>

<style>
.v-event-timed-container {
  margin-right: 0px !important;
}

.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}
</style>